import autocomplete from 'autocompleter'

export default class extends HTMLInputElement {
  constructor () {
    super()

    if (this.dataset.defaultItems) {
      this.defaultItems = JSON.parse(this.dataset.defaultItems)
    } else {
      this.defaultItems = []
    }
  }

  connectedCallback () {
    autocomplete({
      input: this,
      preventSubmit: false,
      minLength: 0,
      showOnFocus: true,
      onSelect: (item) => {
        this.value = item.label

        if (this.onselect) {
          this.onselect(item)
        } else {
          this.dispatchEvent(new CustomEvent('select', { detail: item }))
        }
      },
      render: this.renderItem,
      fetch: this.loadItems
    })
  }

  loadItems = (search, resolve) => {
    const lowerSearch = search.toLowerCase()

    if (search) {
      const queryParams = new URLSearchParams({ q: search })

      fetch('/api/address_autocomplete?' + queryParams).then((response) => {
        return response.json()
      }).then((data) => {
        const filteredItems = this.defaultItems.filter((item) => {
          return item.label.toLowerCase().includes(lowerSearch) ||
                  (item.company_name && item.company_name.toLowerCase().includes(lowerSearch))
        })

        resolve([
          ...filteredItems,
          ...data.filter((item) => !filteredItems.find((i) => i.label === item.label))
        ])
      }).catch(() => {
        resolve([])
      })
    } else {
      resolve(this.defaultItems)
    }
  }

  renderItem (item) {
    const div = document.createElement('div')

    if (item.company_name) {
      div.textContent = [item.company_name, item.label].join(', ')
    } else {
      div.textContent = item.label
    }

    return div
  }
}
