export default class extends HTMLElement {
  connectedCallback () {
    document.body.classList.add('overflow-hidden')

    document.addEventListener('turbo:submit-end', this.handleSubmit)
    document.addEventListener('keyup', this.handleKeyup)
    document.querySelector('[data-role="mask"]').addEventListener('click', this.handleMaskClick)

    document.addEventListener('turbo:before-cache', this.close)
  }

  disconnectedCallback () {
    document.body.classList.remove('overflow-hidden')

    document.removeEventListener('turbo:submit-end', this.handleSubmit)
    document.removeEventListener('keyup', this.handleKeyup)
    document.removeEventListener('turbo:before-cache', this.close)
  }

  close = () => {
    if (this.dataset.closable === 'false') {
      return
    }

    if (this.parentNode) {
      this.parentNode.src = undefined
    }

    this.remove()
  }

  handleMaskClick = (e) => {
    if (e.target.classList.contains('fixed') && !window.getSelection().toString().length) {
      this.close()
    }
  }

  handleKeyup = (e) => {
    if (e.code === 'Escape') {
      this.close()
    }
  }

  handleSubmit = (e) => {
    if (e.detail.success && this.dataset.closeOnSubmit !== 'false') {
      this.close()
    }
  }
}
