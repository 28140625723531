import { Chart } from 'chart.js'

export default class extends HTMLCanvasElement {
  connectedCallback () {
    this.chart = new Chart(this.getContext('2d'), this.chartParams)
  }

  generateDataPoints () {
    const lowerBound = 0
    const upperBound = 1

    const normalY = (x, mean, stdDev) => Math.exp((-0.5) * Math.pow((x - mean) / stdDev, 2))

    const getMean = (lowerBound, upperBound) => (upperBound + lowerBound) / 2

    const getStdDeviation = (lowerBound, upperBound) => (upperBound - lowerBound) / 4

    const generatePoints = (lowerBound, upperBound) => {
      const stdDev = getStdDeviation(lowerBound, upperBound)
      const min = lowerBound - 2 * stdDev
      const max = upperBound + 2 * stdDev
      const unit = (max - min) / 100
      const range = []

      for (let i = min; i < max; i += unit) {
        range.push(i)
      }

      return range
    }

    const mean = getMean(lowerBound, upperBound)
    const stdDev = getStdDeviation(lowerBound, upperBound)
    const points = generatePoints(lowerBound, upperBound)

    return points.map((x) => normalY(x, mean, stdDev))
  }

  get chartParams () {
    const value = parseFloat(this.dataset.value)
    const lowerValue = value * 0.75
    const higherValue = value * 1.25
    const step = (higherValue - lowerValue) / 100

    const prices = [...Array(100).keys()].map((i) => parseInt(lowerValue + (i * step)))
    const data = {
      labels: prices,
      datasets: [{
        fill: true,
        data: this.generateDataPoints(),
        backgroundColor: 'rgba(2, 132, 199, 0.3)',
        borderColor: 'rgb(2, 132, 199)',
        borderWidth: 1
      }]
    }

    return {
      type: 'line',
      data,
      options: {
        layout: {
          padding: {
            top: 40
          }
        },
        scales: {
          y: {
            display: false
          },
          x: {
            ticks: {
              callback: (index) => {
                return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP', minimumFractionDigits: 0 }).format(prices[index])
              }
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: (items) => {
                return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP', minimumFractionDigits: 0 }).format(items[0].label)
              },
              label: (context) => {
                let label = context.dataset.label || ''

                if (label) {
                  label += ': '
                }

                if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('en-UK', { style: 'percent' }).format(context.parsed.y)
                }

                return label
              }
            }
          },
          datalabels: {
            labels: {
              value: {
                display: (e) => [15, 35, 50, 65, 85].includes(e.dataIndex),
                backgroundColor: (context) => {
                  return context.dataset.borderColor
                },
                formatter: (_value, e) => {
                  return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP', minimumFractionDigits: 0 }).format(prices[e.dataIndex])
                },
                color: 'white',
                align: 'end',
                anchor: 'end',
                borderRadius: 4,
                font: {
                  weight: 'bold'
                }
              }
            }
          }
        }
      }
    }
  }
}
