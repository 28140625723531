import { Chart } from 'chart.js'

export default class extends HTMLCanvasElement {
  connectedCallback () {
    this.chart = new Chart(this.getContext('2d'), this.chartParams)
  }

  get chartParams () {
    const data = {
      labels: JSON.parse(this.dataset.labels),
      datasets: [{
        data: JSON.parse(this.dataset.values),
        backgroundColor: 'rgba(2, 132, 199, 0.3)',
        borderColor: 'rgb(2, 132, 199)',
        borderWidth: 1
      }]
    }

    return {
      type: 'bar',
      data,
      options: {
        layout: {
          padding: {
            top: 30
          }
        },
        scales: {
          y: {
            ticks: {
              callback: (value) => {
                return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP', minimumFractionDigits: 0 }).format(value)
              }
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (context) => {
                let label = context.dataset.label || ''

                if (label) {
                  label += ': '
                }

                if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP', minimumFractionDigits: 0 }).format(context.parsed.y)
                }

                return label
              }
            }
          },
          datalabels: {
            labels: {
              value: {
                backgroundColor: (context) => {
                  return context.dataset.borderColor
                },
                formatter: (value) => {
                  return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP', minimumFractionDigits: 0 }).format(value)
                },
                color: 'white',
                align: 'end',
                anchor: 'end',
                borderRadius: 4,
                font: {
                  weight: 'bold'
                }
              }
            }
          }
        }
      }
    }
  }
}
