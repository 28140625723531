export default class extends HTMLInputElement {
  async connectedCallback () {
    const { default: flatpickr } = await import(/* webpackChunkName: "flatpickr" */ 'flatpickr')

    const options = {
      time_24hr: true,
      allowInput: true
    }

    if (this.dataset.noCalendar) {
      options.dateFormat = 'H:i'
    } else if (this.dataset.enableTime) {
      options.dateFormat = 'd/m/Y H:i'
    } else {
      options.dateFormat = 'd/m/Y'
    }

    this.flatpickr = flatpickr(this, { ...options, ...this.dataset })
  }
}
