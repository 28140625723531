// https://github.com/themesberg/flowbite/blob/main/src/components/dropdown.js

import { createPopper } from '@popperjs/core'
import { target, targetable } from '@github/catalyst/lib/targetable'

const defaultOptions = {
  placement: 'bottom',
  triggerType: 'click',
  onShow: () => { },
  onHide: () => { }
}

export default targetable(class extends HTMLElement {
  static [target.static] = [
    'menu',
    'toggleButton'
  ]

  connectedCallback () {
    this._options = { ...defaultOptions, ...this.dataset }
    this._popperInstance = this._createPopperInstace()
    this._visible = false
    this._init()

    document.addEventListener('turbo:before-cache', this.hide)
  }

  disconnectedCallback () {
    document.removeEventListener('turbo:before-cache', this.hide)
  }

  _init () {
    if (this.toggleButton) {
      this.toggleButton.addEventListener('click', () => {
        this.toggle()
      })
    }
  }

  _createPopperInstace () {
    return createPopper(this.toggleButton, this.menu, {
      placement: this._options.placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 10]
          }
        }
      ]
    })
  }

  _handleClickOutside (ev, targetEl) {
    const clickedEl = ev.target
    if (clickedEl !== targetEl && !targetEl.contains(clickedEl) && !this.toggleButton.contains(clickedEl) && this._visible) {
      this.hide()
    }
    document.body.removeEventListener('click', this._handleClickOutside, true)
  }

  toggle () {
    if (this._visible) {
      this.hide()
      document.body.removeEventListener('click', this._handleClickOutside, true)
    } else {
      this.show()
    }
  }

  show () {
    this.menu.classList.remove('hidden')
    this.menu.classList.add('block')

    // Enable the event listeners
    this._popperInstance.setOptions(options => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: 'eventListeners', enabled: true }
      ]
    }))

    document.body.addEventListener('click', (ev) => { this._handleClickOutside(ev, this.menu) }, true)

    // Update its position
    this._popperInstance.update()
    this._visible = true

    // callback function
    this._options.onShow(this)
  }

  hide = () => {
    this.menu.classList.remove('block')
    this.menu.classList.add('hidden')

    this._visible = false

    // callback function
    this._options.onHide(this)
  }
})
