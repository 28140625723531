import '@ungap/custom-elements'
import '@hotwired/turbo-rails'

import ahoy from 'ahoy.js'

import AddressAutocompleteElement from './application/address_autocomplete'
import DropdownMenuElement from './application/dropdown_menu'
import LoadTypeFieldsElement from './application/load_type_fields'
import LocationFieldsElement from './application/location_fields'
import LeafletMapElement from './application/leaflet_map'
import BarChartElement from './application/bar_chart'
import DistributionChartElement from './application/distribution_chart'
import TurboModalElement from './application/turbo_modal'
import FlatpickrInputElement from './application/flatpickr_input'
import posthog from 'posthog-js'

import './images/math.svg'
import './images/factory.svg'
import './images/booking.svg'
import './images/team.png'
import './images/website.svg'
import './images/message-received.svg'

import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  BarElement,
  BarController,
  CategoryScale,
  Tooltip,
  Filler
} from 'chart.js'

import ChartDataLabels from 'chartjs-plugin-datalabels'

ahoy.configure({ cookies: false })

Chart.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  BarElement,
  BarController,
  CategoryScale,
  ChartDataLabels,
  Tooltip,
  Filler
)

window.Posthog ||= posthog.init(process.env.POSTHOG_CLIENT_TOKEN, {
  api_host: 'https://eu.posthog.com',
  autocapture: false,
  enable_recording_console_log: true,
  capture_pageview: false,
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: {
      password: true
    }
  }
})

if (document.body.dataset.currentUserEmail) {
  posthog.identify(document.body.dataset.currentUserEmail)
}

window.customElements.define('bar-chart', BarChartElement, { extends: 'canvas' })
window.customElements.define('distribution-chart', DistributionChartElement, { extends: 'canvas' })
window.customElements.define('address-autocomplete', AddressAutocompleteElement, { extends: 'input' })
window.customElements.define('flatpickr-input', FlatpickrInputElement, { extends: 'input' })
window.customElements.define('turbo-modal', TurboModalElement)
window.customElements.define('dropdown-menu', DropdownMenuElement)
window.customElements.define('load-type-fields', LoadTypeFieldsElement)
window.customElements.define('location-fields', LocationFieldsElement)
window.customElements.define('leaflet-map', LeafletMapElement)

const originalReferer = document.referrer

document.addEventListener('turbo:load', () => {
  ahoy.track('pageView', { url: document.location.href, referer: originalReferer })
})
