import 'leaflet/dist/leaflet.css'

const markerColors = {
  pickup: {
    bg: '#0284c7',
    border: '#0369a1'
  },
  delivery: {
    bg: '#0284c7',
    border: '#0369a1'
  }
}

function buildMarkerSvg (type, index) {
  const colors = markerColors[type]
  const letter = String.fromCharCode(65 + index)

  return `<svg xmlns="http://www.w3.org/2000/svg" width="37" height="37"  viewBox="0 0 26 37"><g fill="none"><path d="M13 0C5.817 0 0 5.773 0 12.918c0 7.655 5.59 10.526 9.555 17.16C12.09 34.321 11.342 37 13 37c1.723 0 .975-2.743 3.445-6.858C20.085 23.86 26 20.605 26 12.918 26 5.773 20.183 0 13 0z" fill="${colors.border}"/><path d="M13.017 35c-.233 0-.3-.065-.7-1.275-.332-1.046-.832-2.648-2.13-4.61-1.265-1.928-2.596-3.498-3.861-5.002C3.363 20.517 1 17.706 1 12.64 1.033 6.199 6.393 1 13.017 1S25 6.23 25 12.639c0 5.067-2.33 7.911-5.326 11.507-1.232 1.504-2.53 3.073-3.795 4.97-1.265 1.928-1.797 3.498-2.13 4.544-.4 1.275-.5 1.34-.732 1.34z" fill="${colors.bg}"/><text text-anchor="middle" dy=".3em" x="13" y="14" font-family="Roboto, Arial, sans-serif" font-size="16px" fill="#FFF">${letter}</text></g></svg>`
}

const PRECISION = 1e5

function decodePolyline (value) {
  const points = []
  let lat = 0
  let lon = 0

  integers(value, (x, y) => {
    lat += x
    lon += y
    points.push([lat / PRECISION, lon / PRECISION])
  })

  return points
}

function sign (value) {
  return value & 1 ? ~(value >>> 1) : (value >>> 1)
}

function integers (value, callback) {
  let values = 0
  let x = 0
  let y = 0

  let byte = 0
  let current = 0
  let bits = 0

  for (let i = 0; i < value.length; i++) {
    byte = value.charCodeAt(i) - 63
    current = current | ((byte & 0x1F) << bits)
    bits = bits + 5

    if (byte < 0x20) {
      if (++values & 1) {
        x = sign(current)
      } else {
        y = sign(current)
        callback(x, y)
      }
      current = 0
      bits = 0
    }
  }

  return values
}

export default class extends HTMLElement {
  async connectedCallback () {
    const { default: Leaflet } = await import(/* webpackChunkName: "leaflet" */ 'leaflet')

    const markerValues = JSON.parse(this.dataset.markers || '[]')
    const polylineValues = JSON.parse(this.dataset.polylines || '[]').map((data) => decodePolyline(data)).flat()

    const bounds = new Leaflet.LatLngBounds(polylineValues)

    const layer = new Leaflet.TileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }
    )

    this.map = Leaflet.map(this, { layers: [layer], zoomControl: false })
    this.map.attributionControl.setPrefix(false)

    this.map.fitBounds(bounds, { padding: [15, 15] })

    this.addMarkers(Leaflet, markerValues)

    if (polylineValues.length) {
      this.addPolyline(Leaflet, polylineValues)
    }
  }

  addPolyline (Leaflet, polylineCoordinates) {
    if (polylineCoordinates.length) {
      const polyline = new Leaflet.Polyline(polylineCoordinates, {
        color: '#0284c7',
        weight: 6,
        opacity: 0.9,
        smoothFactor: 1
      })

      polyline.addTo(this.map)
    }
  }

  addMarkers (Leaflet, markerValues) {
    markerValues.map((data, index) => {
      const icon = Leaflet.divIcon({
        html: buildMarkerSvg(data.type, index),
        className: '',
        iconSize: [24, 40],
        iconAnchor: [16, 36]
      })

      const marker = Leaflet.marker(Object.values(data.position), { icon })

      marker.bindPopup(data.title)
      marker.addTo(this.map)

      return marker
    })
  }
}
