import { actionable } from '@github/catalyst/lib/actionable'
import { target, targetable } from '@github/catalyst/lib/targetable'

export default actionable(targetable(class extends HTMLElement {
  static [target.static] = [
    'fromLocationInput',
    'toLocationInput'
  ]

  findOrCreateRoute () {
    fetch('/api/find_or_create_route', {
      method: 'POST',
      body: JSON.stringify({
        from_address: this.fromLocationInput.value,
        to_address: this.toLocationInput.value
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
}))
