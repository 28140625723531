import { actionable } from '@github/catalyst/lib/actionable'
import { target, targetable } from '@github/catalyst/lib/targetable'

export default actionable(targetable(class extends HTMLElement {
  static [target.static] = [
    'loadTypeSelect',
    'numberOfPalletsSelect',
    'loadConditionSelect',
    'loadTypePreview',
    'palletsTypeSelect'
  ]

  connectedCallback () {
    this.update()
  }

  update () {
    this.numberOfPalletsSelect.parentNode.classList.toggle('hidden', this.loadTypeSelect.value !== 'palletised')
    this.loadConditionSelect.parentNode.classList.toggle('hidden', this.loadTypeSelect.value !== 'palletised')

    if (this.palletsTypeSelect) {
      this.palletsTypeSelect.parentNode.classList.toggle('hidden', this.loadTypeSelect.value !== 'palletised')
    }

    this.loadTypePreview.innerHTML = this.buildPreview()
  }

  buildPreview () {
    const result = []

    if (this.loadTypeSelect.value === 'palletised') {
      if (this.numberOfPalletsSelect.value === '33') {
        result.push('Palletised')
        result.push('Full Load')
      } else {
        result.push(`${this.numberOfPalletsSelect.value} pallets`)
      }
    } else {
      result.push(this.loadTypeSelect.options[this.loadTypeSelect.selectedIndex].text)
    }

    if (this.loadConditionSelect.value !== 'ambient') {
      result.push(this.loadConditionSelect.options[this.loadConditionSelect.selectedIndex].text)
    }

    return result.join(', ')
  }
}))
